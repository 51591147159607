<template>
    <PTabs
      v-if="site && installed && settings && session"
      :tabs='tabs'
      :selected="selectedTab"
      @select="selectMenu"
      navigation
    />
</template>

<script>
export default {
  name: 'Header',
  props: ['site', 'installed', 'settings', 'session'],
  data(){
    return {
      drawer: false,
      group: null,
      isMobile: false,
      routeInfo: '',
      selectedTab:0,
      tabs: [
        {
          "id": "dashboard",
          "content": "Dashboard",
          "path": "/home",
          "to": "/home"
        },
        {
          "id": "orders",
          "content": "Orders",
          "path": "/orders",
          "to": "/orders"
        },
        {
          "id": "nfts",
          "content": "NFTs",
          "path": "/nfts",
          "to": "/nfts"
        },
        {
          "id": "settings",
          "content": "Settings",
          "path": "/settings",
          "to": "/settings"
        }
      ]
    }
  },
   created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      selectMenu(menuIndex){
        this.selectedTab=menuIndex
      },
      handleResize() {
          if(window.innerWidth < 769){
            this.isMobile = true;
          }
          else{
            this.isMobile = false;
          }
      }
  },
  mounted: async function () {
    await this.selectMenu('0')
  }
}
</script>
<style scoped>
.header {
  width: 100%;
}
.header-image {
  max-width: 150px;
  padding: 5px 0;
}
.header-logo,
.header-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.header-logo a  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.header-links {
  padding: 0;
}
.header-links a {
  text-decoration: none;
  color: #2c3e50;
  padding: 0 20px;
}
.v-card {
  height: 0;
}
.v-list-item-group {
  display: flex;
}
.v-list-item {
  margin-bottom: 0 !important;
  height: 100%;
}
.v-list-item:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 768px){
  .v-list-item-group {
  flex-direction: column;
}
  .header-logo {
    padding-left: 30px;
  }
  .v-card {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: transparent !important;
  }
  .v-list-item a {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 48px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    color: #2c3e50;
  }
  .v-card.isActive {
    height: 100vh !important;
    background: #fff !important;
    z-index: 9;
  }
}
</style>