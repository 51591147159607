<template>
  <div class="products-wrapper">
      <form v-if="modalOpen || editModalOpen">
            <input
            @change=fileUpload
        id="imageUpload"
        type="file"
             class="input"
            accept="image/*"
            label="Image"
            :labelHidden="false"
            :emptyLabel="false"
              />
              </form>
      <PModal
      :close="modalChange"
      :open="modalOpen"
      sectioned
      :primaryAction='{"content":"Save NFT","onAction":save}'
      :secondaryActions='[{"content":"Cancel","destructive":true, "onAction":modalChange}]'
      title="Enter NFT Details"
    >
      <Loading v-if="isLoading"></Loading>
      <PFormLayout v-else>
        <PSubheading class="label" element="h2">Name*</PSubheading>
        <PTextField
            class="input"
            label="Name"
            v-model='name'
            :labelHidden="false"
            :emptyLabel="false" />
      <PSubheading class="label" element="h2">Description*</PSubheading>
        <PTextField
             class="input"
            label="Description"
            v-model='description'
            :labelHidden="false"
            :emptyLabel="false"
            :minHeight="100"
            :multiline="true" />
     <PSubheading class="label" element="h2">Image*</PSubheading>
        <div @click=imageUpload id="imageUploadButton">
          <PSubheading class="label" element="h2">Click to upload an image</PSubheading>
        </div>
        <img class="nft-img" v-if="this.image !== '' && this.image !== undefined" :src="`${image}`" />
        <PSubheading class="label" element="h2">External URL</PSubheading>
          <PTextField
               class="input"
            label="External URL"
            v-model='externalUrl'
            :labelHidden="false"
            :emptyLabel="false" />
                    <PSubheading class="label" element="h2">Properties</PSubheading>
                    <div class="props-container">


                      <template v-for="(attribute, index) in pCount">
                        <template v-if="attributes[index]">
                        <PTextField
                          :key="attribute"
                          class="input prop-input"
                          label="Properties"
                          placeholder="Label"
                          v-model="attributes[index].name"
                          :labelHidden="false"
                          :emptyLabel="false" />
                            <PTextField
                            :key="attribute"
                            v-model="attributes[index].value"
                            placeholder="Value"
                            class="input value-input"
                          label="Properties"
                          :labelHidden="false"
                          :emptyLabel="false" />
                        </template>
                      </template>

                  </div>
             <PButton
             v-if="!maxProps"
             @click="addProp()"
                   class="add-prop">
          +</PButton>
              <PSubheading class="label" element="h2">Animation URLS</PSubheading>
                    <div class="props-container">
          <PTextField
            class="input animation-input"
            label="Properties"
            placeholder="Type"
            :labelHidden="false"
            :emptyLabel="false" />
          <PTextField
              placeholder="URL"
               class="input animation-value"
            label="Properties"
            :labelHidden="false"
            :emptyLabel="false" />
          </div>
          <PSubheading class="label" element="h2">Max Supply - Can NOT be changed</PSubheading>
                                 <PStack vertical="" v-if="!isLoading">
    <PStackItem>
      <PRadioButton
      v-if="maxSupplyInput === 'Enable'"
        id="Enable"
        label="Enable"
        helpText=""
        name="Enable"
        checked
        v-model="maxSupplyInput"
      />
      <PRadioButton
            v-else
        id="Enable"
        label="Enable"
        helpText=""
        name="Auto"
        v-model="maxSupplyInput"
        :checked=false
      @change="changeMaxSupply('Enable')"
      />
            <PRadioButton
      v-if="maxSupplyInput === 'Disable'"
        id="Disable"
        label="Disable"
        helpText=""
        name="Disable"
        checked
        v-model="maxSupplyInput"
      />
      <PRadioButton
            v-else
        id="Disable"
        label="Disable"
        helpText=""
        name="Disable"
        v-model="maxSupplyInput"
        :checked=false
      @change="changeMaxSupply('Disable')"
      />
    </PStackItem>
  </PStack>
        <PTextField
        v-if="maxSupplyInput === 'Enable'"
            class="input"
            label="Max Supply"
            v-model='maxSupply'
            :labelHidden="false"
            :emptyLabel="false" />
          <PSubheading class="label" element="h2">Note * = Required Field</PSubheading>

        <PBanner v-if="errors.length > 0" title="Errors" status="critical" :action="{}">
          {{ errors }}
        </PBanner>
      </PFormLayout>
    </PModal>
    <PModal
      :close="editModalChange"
      :open="editModalOpen"
      sectioned
      :primaryAction='{"content":isUpdate === true && published === true ? "Update NFT" : "Save NFT", "onAction":isUpdate === true && published === true ? update: publish}'
      :secondaryActions='[{"content":"Cancel","destructive":true, "onAction":editModalChange}]'
      title="Edit NFT Details"
    >
      <Loading v-if="isLoading"></Loading>
      <PFormLayout v-else>
        <PSubheading class="label" element="h2">Name*</PSubheading>
        <PTextField
        class="input"
            label="Name"
            v-model='name'
            :labelHidden="false"
            :emptyLabel="false" />
      <PSubheading class="label" element="h2">Description*</PSubheading>
        <PTextField
            class="input"
            label="Description"
            v-model='description'
            :labelHidden="false"
            :emptyLabel="false"
            :minHeight="100"
            :multiline="true" />
        <PSubheading class="label" element="h2">Image*</PSubheading>

        <PTextField
             class="input"
            accept="image/*"
            label="Image"
            v-model='image'
            :labelHidden="false"
            :emptyLabel="false"
            :disabled="true" />
            <img class="nft-img" v-if="this.image !== ''&& this.image !== undefined && isUpdate === true" :src="`${image}`" />
            <PSubheading class="label" element="h2">External URL</PSubheading>
          <PTextField
          class="input"
            label="External URL"
            v-model='externalUrl'
            :labelHidden="false"
            :emptyLabel="false" />
            <PSubheading class="label" element="h2">Properties</PSubheading>
                                <div class="props-container">

          <template v-for="(attribute, index) in pCount">
            <template v-if="attributes[index]">
            <PTextField
            :key="attribute"
              class="input prop-input"
              label="Properties"
              placeholder="Label"
              v-model="attributes[index].name"
              :labelHidden="false"
              :emptyLabel="false" />
                <PTextField
                :key="attribute"
                v-model="attributes[index].value"
                placeholder="Value"
                class="input value-input"
              label="Properties"
              :labelHidden="false"
              :emptyLabel="false" />
             </template>
          </template>
                                </div>
                       <PButton
             v-if="!maxProps"
             @click="addPropEdit()"
                   class="add-prop">
          +</PButton>

          <PSubheading class="label" element="h2">Animation URLS</PSubheading>
                    <div class="props-container">
          <PTextField
          v-if="animationUrls[0]"
          v-model="animationUrls[0].type"
            class="input animation-input"
            label="Properties"
            placeholder="Type"
            :labelHidden="false"
            :emptyLabel="false" />
                      <PTextField
          v-else
            class="input animation-input"
            label="Properties"
            placeholder="Type"
            :labelHidden="false"
            :emptyLabel="false" />
          <PTextField
                    v-if="animationUrls[0]"
          v-model="animationUrls[0].value"
              placeholder="URL"
               class="input animation-value"
            label="Properties"
            :labelHidden="false"
            :emptyLabel="false" />
                      <PTextField
              v-else
              placeholder="URL"
               class="input animation-value"
            label="Properties"
            :labelHidden="false"
            :emptyLabel="false" />
          </div>

                      <PSubheading class="label" element="h2">Max Supply</PSubheading>

        <PTextField
        disabled
            class="input"
            label="Max Supply"
            v-model='maxSupply'
            :labelHidden="false"
            :emptyLabel="false" />
          <PSubheading class="label" element="h2">Note * = Required Field</PSubheading>

          <div class="publish-div" v-if="isUpdate === true && productSelected === true">
            <PSubheading class="label selected-product-title" element="h2">Additional Shopify Fields</PSubheading>
            <PSubheading class="label margin-label" element="h2">Tags: Comma Separate</PSubheading>
            <PTextField
            class="input"
            label="Tags: Comma Separate"
            v-model='tags'
            :labelHidden="false"
            :emptyLabel="false" />
            <PToggle
            @change="changeToggle('draft')"
            class="toggle"
            v-model="draft"
            :checked="draft"
            label="Draft Product" />
            <div>Select to push the product to Shopify as a draft product</div>

            <PToggle
            @change="changeToggle('publish')"
            class="toggle"
            v-model="publishedProduct"
            :checked="publishedProduct"
            label="Publish Product" />
            <div>Select to push the product to Shopify as a published product live for your customers.</div>
          </div>

        <div>
          <a :href="`${this.trackingEndpoint}/address/${contractAddress}#code`" class="Polaris-Button Polaris-Button--primary" target="_blank">View Smart NFT Contract</a>
        </div>


        <template v-if="!archived">
          <PSubheading class="label" element="h2">Archive NFT</PSubheading>
          <p>Archived NFTs can not be sold on your store and will not appear by default.</p>
      <PButton
      @click='archiveNft'
      class="submit-form Polaris-Button--destructive"
      >Archive NFT</PButton>
      </template>
              <template v-else>
          <PSubheading class="label" element="h2">Unarchive NFT</PSubheading>
          <p>Archived NFTs can not be sold on your store and will not appear by default.</p>
      <PButton
      @click='unArchiveNft'
      class="submit-form Polaris-Button--primary"
      >Unarchive NFT</PButton>
      </template>

        <PBanner v-if="errors.length > 0" title="Errors" status="critical" :action="{}">
          {{ errors }}
        </PBanner>
      </PFormLayout>
    </PModal>
    <Loading v-if="isLoading"></Loading>
    <div class="products" v-else>
      <PButton
      v-if="nftsAllowed"
      @click='create'
      class="submit-form"
      >{{ buttonTxtCreate }}</PButton>
      <p v-else class="errors" >You have reached the maximum NFTs for your plan. You can change your plan <router-link class="text-link text-center" to="/settings">here.</router-link></p>
        <PTextField
          v-if="nfts.length > 0"
          class="search-form"
          id="input_field"
          value="Hello World!"
          :minHeight="0"
          :multiline="false"
          type="text"
          :disabled="false"
           @input="handleSearch"
          placeholder="Search NFTs"
          v-model='search'
        >
        </PTextField>
                      <PButton
      v-if="showArchived"
      @click='showLive'
      class="submit-form"
      >Show Live NFTs</PButton>
      <PButton
      v-else
      @click='showArchivedNfts'
      class="submit-form"
      >Show Archived NFTs</PButton>
      <div class="product-container" v-if="nfts.length > 0">
        <Loading v-if="isLoadingProducts"></Loading>
          <PPage v-else
          v-for="nft in displayNfts" :key="nft.id"
        class="product"
        :class="{ active: product_id === nft.id }"
        :title="`${nft.nft.name}`"
        :thumbnail="`${nft.nft.image}`"
        :secondaryActions='[{"content":"Edit","accessibilityLabel":"Edit","icon":"","onAction":function onAction(){selectNft(nft.id)}}]'
        :subtitle="`${truncateText(nft.nft.description)}`"
        separator
      >
       <PBadge v-if=nft.nft.archived slot="titleMetadata" status="critical">Archived</PBadge>
      <PBadge v-else-if=nft.nft.published slot="titleMetadata" status="success">Live</PBadge>
      <PBadge v-else slot="titleMetadata" status="fail">Not Published</PBadge>
      </PPage>
        <Loading v-if="loadingMoreProducts"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import Loading from '../components/Loading.vue';
import axios from 'axios';

export default {
  name: 'NFTs',
  props: ['site', 'settings', 'plan'],
  components: {
    Loading
  },
  data(){
    return {
     modalOpen: false,
     editModalOpen: false,
     isLoading: true,
     isLoadingProducts: false,
     title: "NFTs",
     product_id: '',
     selectedProduct: '',
     id: '',
     name: '',
     description: '',
     image: '',
     externalUrl: '',
     attributes: [],
     published: false,
     fungible: false,
     contractAddress: '',
     errors: '',
     tokenId: '',
     isOk: false,
     alert: false,
     timeout: 2500,
     apiUrl: process.env.VUE_APP_BACKEND,
     trackingEndpoint: process.env.VUE_APP_TRACKING_ENDPOINT,
     nfts: [],
     displayNfts: [],
     productSelected: false,
     buttonTxtCreate: 'Create New NFT',
     buttonTxtSave: 'Save NFT',
     buttonTxtPublish: 'Publish NFT',
     selectedTxt: 'New NFT',
     search: '',
     loadingMoreProducts: false,
     overlay: false,
     nftAlertTxt: 'NFT Saved!',
     isUpdate: false,
     draft: true,
     publishedProduct: false,
     tags: "",
     price: '',
     shopify_id: '',
     pIndex: 1,
     pCount: [ 1 ],
     maxProps: false,
     modalClose: false,
     editModalClose: false,
     animationUrls: [],
     backgroundColor: "#FFFFFF",
     nftsAllowed: true,
     maxSupply: 0,
     showArchived: false,
     archived: false,
     maxSupplyInput: 'Disable',
     shopifyDemoStore: process.env.VUE_APP_SHOPIFY_DEMO_STORE
    }
  },
  methods: {
    changeMaxSupply(maxSupplyInput){
      this.maxSupplyInput = maxSupplyInput;
      if(maxSupplyInput === 'Enable'){
        this.maxSupply = 0;
      }
      else{
        this.maxSupply = 'disabled';
      }
    },
    async archiveNft(){
        let buttons = Array.from(document.getElementsByClassName('Polaris-ButtonGroup'))
        for(let button of buttons){
          button.style.display = 'none';
        }
        this.isLoading = true;
      let nft = {
        site: this.site,
        shopify_id: this.shopify_id
      }
              await axios({
        method:'post',
        url: `${this.apiUrl}shopify/product/archive`,
        data: nft
        }).then(function(){
        }).catch(function() {
        });
                await firebase.firestore().collection('nfts').doc(this.id).update(
          {
            "nft": {
              "name": this.name,
              "description": this.description,
              "externalUrl": this.externalUrl,
              "price": this.price,
              "publishedProduct": false,
              "draft": this.draft,
              "tags": this.tags,
              "image": this.image,
              "published": this.published,
              "fungible": this.fungible,
              "attributes": this.attributes,
              "animationUrls": this.animationUrls,
              "backgroundColor": this.backgroundColor,
              "maxSupply": this.maxSupply,
              "archived": true,
            }
          }
          );
              await this.getNfts();
        this.overlay = false;
        this.$pToast.open({
            message: "NFT Archived!"
        });
        this.productSelected = false;
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.editModalOpen = false;
        this.isLoading = false;
        for(let button of buttons){
          button.style.display = 'none';
        }
    },
    async unArchiveNft(){
              let buttons = Array.from(document.getElementsByClassName('Polaris-ButtonGroup'))
        for(let button of buttons){
          button.style.display = 'none';
        }
            this.isLoading = true;
            let nft = {
        site: this.site,
        shopify_id: this.shopify_id
      }
              await axios({
        method:'post',
        url: `${this.apiUrl}shopify/product/unarchive`,
        data: nft
        }).then(function(){
        }).catch(function() {
        });
                await firebase.firestore().collection('nfts').doc(this.id).update(
          {
            "nft": {
              "name": this.name,
              "description": this.description,
              "externalUrl": this.externalUrl,
              "price": this.price,
              "publishedProduct": true,
              "draft": this.draft,
              "tags": this.tags,
              "image": this.image,
              "published": this.published,
              "fungible": this.fungible,
              "attributes": this.attributes,
              "animationUrls": this.animationUrls,
              "backgroundColor": this.backgroundColor,
              "maxSupply": this.maxSupply,
              "archived": false,
            }
          }
          );          await this.getNfts();
        this.overlay = false;
        this.$pToast.open({
            message: "NFT Unarchived!"
        });
        this.productSelected = false;
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.editModalOpen = false;
        this.isLoading = false;
        for(let button of buttons){
          button.style.display = 'none';
        }
    },
    showArchivedNfts(){
      this.showArchived = true;
      this.isLoadingProducts = true;
      this.displayNfts = this.nfts.filter(nft => nft.nft.archived == this.showArchived);
      this.isLoadingProducts = false;
    },
    showLive(){
      this.showArchived = false;
      this.isLoadingProducts = true;
      this.displayNfts = this.nfts.filter(nft => nft.nft.archived == this.showArchived);
      this.isLoadingProducts = false;
    },
    changeToggle(toggle){
      if(toggle === 'draft'){
        if(this.draft){
          this.draft = false
        }
        else{
          this.draft = true;
        }
      }
      else{
        if(this.publishedProduct){
          this.publishedProduct = false;
        }
        else{
          this.publishedProduct = true;
        }
      }
    },
    fileUpload(){
      let file = document.getElementById("imageUpload").files[0] || this.image
      this.image = URL.createObjectURL(file)
    },
    addPropEdit(){


      if(this.pIndex < 11){
        this.pIndex ++;
        this.pCount.push(this.pIndex)
        this.attributes.push({"name": "", "value": ""})
      }
      else{
        this.maxProps = true;
      }
    },
    addProp(){
      if(this.pIndex < 11){
        this.pIndex ++;
        this.pCount.push(this.pIndex)
        this.attributes.push({"name": "", "value": ""})
      }
      else{
        this.maxProps = true;
      }
    },
    imageUpload(){
      let el = document.getElementById("imageUpload");
      el.click();
    },
    editModalChange(){
      if(this.editModalOpen){
        this.editModalOpen = false
        this.editModalClose = true;
      }
      else{
        this.editModalOpen = true;
        this.editModalClose = false;
      }
    },
    modalChange(){
      if(this.modalOpen){
        this.modalOpen = false
        this.modalClose = true
      }
      else{
        this.modalOpen = true
        this.modalClose = false
      }
    },
    handleSearch(){
      this.isLoadingProducts = true;
      if(this.search.length > 0){
        this.displayNfts = this.nfts.filter(nft => ( nft.nft.name.toLowerCase().includes(this.search.toLowerCase()) || nft.nft.name.toLowerCase() === this.search.toLowerCase() ) && nft.nft.archived == this.showArchived);
        console.log(this.displayNfts.length)
        if(this.displayNfts.length < 1){
          this.displayNfts = []
        }
      }else{
        this.displayNfts = this.nfts
      }
      this.isLoadingProducts = false;
    },
    async selectNft(product){
        this.errors = '';
        this.editModalOpen = true;
        this.product_id = product;
        this.productSelected = true;
        this.isUpdate = true;
        this.selectedProduct = this.nfts.find(product => product.id === this.product_id);

        this.id = this.selectedProduct.id;
        this.name = this.selectedProduct.nft.name;
        this.description = this.selectedProduct.nft.description;
        this.image = this.selectedProduct.nft.image;
        this.externalUrl = this.selectedProduct.nft.externalUrl;
        this.published = this.selectedProduct.nft.published;
        this.price =        this.selectedProduct.nft.price;
        this.tags = this.selectedProduct.nft.tags;
        this.draft =   this.selectedProduct.nft.draft;
        this.publishedProduct = this.selectedProduct.nft.publishedProduct;
        this.tokenId = this.selectedProduct.tokenId;
        this.shopify_id = this.selectedProduct.shopify_id;
        this.attributes = this.selectedProduct.nft.attributes;
        this.animationUrls = this.selectedProduct.nft.animationUrls;
        this.backgroundColor = this.selectedProduct.nft.backgroundColor;
        this.maxSupply = this.selectedProduct.nft.maxSupply
        this.archived = this.selectedProduct.nft.archived

        let contractAddress = ''
        if(this.selectedProduct.contractAddress === undefined){

          contractAddress = await axios({
            method:'get',
            url: `${this.apiUrl}nft/get-contract-address/${this.id}/${this.site}`,
            }).then(function(response){
              return response.data;
            }).catch(function() {
          });

        }else{
          contractAddress = this.selectedProduct.contractAddress
        }

        this.contractAddress = contractAddress
        this.selectedTxt = 'Update NFT Template';
        this.pCount = [];
        if(this.attributes && this.attributes.length){
        this.pIndex = this.attributes.length;
          for( let [i] of this.attributes.entries()){
              this.pCount.push(i)
          }
        }
        else{
         this.pIndex = 1;
         this.pCount = [ 1 ];
        }

        setTimeout(function(){
        let el = Array.from(document.getElementsByClassName('Polaris-Modal-CloseButton'))[0]
        el.addEventListener("click", function(){
          let b = Array.from(document.getElementsByClassName('Polaris-Button--destructive'))[0]
          b.click();
        });
        }, 1000);
    },
    checkSubmit(){
      this.errors = '';
      //console.log(this.name, this.description, this.image, this.productSelected, this.plan)
      let planOk = true;
      if(this.image !== undefined && this.image.size !== undefined){
        switch(this.plan){
            case 'Basic':
            planOk = this.image.size > 5000000 ? false : true;
            break;
            case 'Pro':
            planOk = this.image.size > 25000000 ? false : true;
            break;
            default:
            planOk = true;
        }
      }
      if(this.productSelected && this.name !== '' &&
      this.description !== '' && this.image !== undefined && planOk){
        this.isOk = true;
      }
      else{
        if(this.name === ''){
          this.errors = "You must enter a name. "
        }
        if(this.description === ''){
          this.errors += "You must enter a description. "
        }
        if(this.image === undefined){
          this.errors += "You must add an image. "
        }
        if(planOk === false){
          this.errors += "The image file size is too big for your plan. "
        }
        this.errors += 'Invalid data. Please try again.';
      }
    },
    create(){
        this.errors = '';
        this.modalOpen = true;
        this.productSelected = true;
        this.selectedTxt = 'New NFT Template';
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.id = '';
        this.name = '';
        this.description = '';
        this.image = '';
        this.externalUrl = '';
        this.published = false;
        this.price =   '';
        this.tags = '';
        this.draft =   false;
        this.publishedProduct = false;
        this.tokenId = '';
        this.shopify_id = '';
        this.errors = '';
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.id = '';
        this.name = '';
        this.description = '';
        this.image = '';
        this.externalUrl = '';
        this.published = false;
        this.price =   '';
        this.tags = '';
        this.tokenId = '';
        this.shopify_id = '';
        this.pIndex = 1;
        this.pCount = [ 1 ];
        this.attributes = [];
        this.animationUrls = [];
        this.backgroundColor = '';
        this.maxSupply = 'disabled';
        this.archived = false
        this.maxSupplyInput = "Disable"

        setTimeout(function(){
        let el = Array.from(document.getElementsByClassName('Polaris-Modal-CloseButton'))[0]
        el.addEventListener("click", function(){
          let b = Array.from(document.getElementsByClassName('Polaris-Button--destructive'))[0]
          b.click();
        });
        }, 1000);
    },
    async save(){
        this.image = document.getElementById("imageUpload").files[0];
        let propsL = Array.from(document.getElementsByClassName('prop-input'))
        let propsV = Array.from(document.getElementsByClassName('value-input'))
        let allPropsL = [];
        let allPropsV = [];
        let animationL = Array.from(document.getElementsByClassName('animation-input'))
        let animationV = Array.from(document.getElementsByClassName('animation-value'))
        let animtaionLS = [];
        let animationVS = [];

        for(let p of propsL){
         console.log(p)
         //let pl = p.getElementsByTagName('input')[0].value;
         let pl = p.getElementsByClassName("Polaris-TextField__Input")[0].value;
         console.log(pl)
         allPropsL.push(pl)
        }

        for(let p of propsV){
          console.log(p)
         //let pl = p.getElementsByTagName('input')[0].value;
         let pl = p.getElementsByClassName("Polaris-TextField__Input")[0].value;
         console.log(pl)
         allPropsV.push(pl)
        }

        for(let p of animationL){
         //let pl = p.getElementsByTagName('input')[0].value;
         let pl = p.getElementsByClassName("Polaris-TextField__Input")[0].value;
         animtaionLS.push(pl)
        }

        for(let p of animationV){
         //let pl = p.getElementsByTagName('input')[0].value;
         let pl = p.getElementsByClassName("Polaris-TextField__Input")[0].value;
         animationVS.push(pl)
        }

        this.attributes = [];
        this.animationUrls = [];

        console.log(`======================= SAVE PROPS L ==========================`)
        console.log(allPropsL)
        console.log(`======================= SAVE PROPS L ==========================`)

        console.log(`======================= SAVE PROPS V ==========================`)
        console.log(allPropsV)
        console.log(`======================= SAVE PROPS V ==========================`)

        if(allPropsL.length && allPropsV.length){
            for(let [i, prop] of allPropsL.entries()){
            if(prop !== '' && allPropsV[i] !== ''){
              let propObj = {
                "type": "property",
                "name": prop,
                "value": allPropsV[i]
              }
              this.attributes.push(propObj)
            }
          }
        }

      if(animtaionLS.length && animationVS.length){
          for(let [i, prop] of animtaionLS.entries()){
          if(prop !== '' && animationVS[i] !== ''){
            let propObj = {
              "type": prop,
              "value": animationVS[i]
            }
            this.animationUrls.push(propObj)
          }
        }
      }

      this.isLoading = true;
      this.checkSubmit();
      if(this.isOk){
        let buttons = Array.from(document.getElementsByClassName('Polaris-ButtonGroup'))
        for(let button of buttons){
          button.style.display = 'none';
        }
        this.alert = false;
        this.overlay = true;
        let today = Date.now();
        let randomNum = Math.floor((Math.random() * 9999999999) + 999);
        this.id = `${today}_${randomNum}`;
        let storageRef = firebase.storage().ref();
        let imageRef = storageRef.child(`/nfts/${this.id}-${this.image.name}`);
        await imageRef.put(this.image).then(async function() {
        });

          this.image =  `https://storage.googleapis.com/${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}/nfts/${this.id}-${this.image.name}`

          let nft = {
          site: this.site,
          id: this.id,
          minted: 0,
          nft: {
            name: this.name,
            description: this.description,
            image: this.image,
            externalUrl: this.externalUrl,
            fungible: this.fungible,
            published: this.published,
            attributes: this.attributes,
            animationUrls: this.animationUrls,
            backgroundColor: this.backgroundColor,
            maxSupply: this.maxSupply,
          }
        }

        nft.nft.price = this.price;
        nft.nft.tags = this.tags;
        nft.nft.draft = this.draft;
        nft.nft.publishedProduct = this.publishedProduct;
        nft.nft.archived = false;

        console.log(`======================= SAVE NFT ==========================`)
        console.log(nft)
        console.log(`======================= SAVE NFT ==========================`)

        await firebase.firestore().collection('nfts').doc(this.id).set(nft);
        await this.getNfts();
        this.overlay = false;
        this.$pToast.open({
            message: this.nftAlertTxt
        });
        this.productSelected = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.modalOpen = false;
        this.isLoading = false;
        for(let button of buttons){
          button.style.display = 'none';
        }
      }
      else{
        this.isLoading = false;
      }
    },
    async publish(){
        let propsL = Array.from(document.getElementsByClassName('prop-input'))
        let propsV = Array.from(document.getElementsByClassName('value-input'))

        let allPropsL = [];
        let allPropsV = [];

        for(let p of propsL){
         let pl = p.getElementsByTagName('input')[0].value;
         allPropsL.push(pl)
        }

        for(let p of propsV){
         let pl = p.getElementsByTagName('input')[0].value;
         allPropsV.push(pl)
        }

        this.attributes = [];

        if(allPropsL.length && allPropsV.length){
            for(let [i, prop] of allPropsL.entries()){
            if(prop !== '' && allPropsV[i] !== ''){
              let propObj = {
                "type": "property",
                "name": prop,
                "value": allPropsV[i]
              }
              this.attributes.push(propObj)
            }
          }
        }

      this.isLoading = true;
      this.checkSubmit();
      if(this.isOk){
        let buttons = Array.from(document.getElementsByClassName('Polaris-ButtonGroup'))
        for(let button of buttons){
          button.style.display = 'none';
        }
        this.isLoading = true;
        this.alert = false;
        this.overlay = true;
        let nft = {
          site: this.site,
          id: this.id,
          minted: 0,
          nft: {
            name: this.name,
            description: this.description,
            image: this.image,
            externalUrl: this.externalUrl,
            fungible: false,
            published: false,
            attributes: this.attributes,
            animationUrls: this.animationUrls,
            backgroundColor: this.backgroundColor,
            maxSupply: this.maxSupply,
          }
        }

        let nftResults = await axios({
        method:'post',
        url: `${this.apiUrl}nft/create`,
        data: nft
        }).then(function(response){
          return response.data;
        }).catch(function() {
        });

        if(nftResults){
          nft.nft.published = true;
          nft.template = nftResults.nft;
          nft.destination = nftResults.destination;
          nft.tokenId = nftResults.tokenId
          nft.typeId = nftResults.nft.id
        }
        nft.nft.price = this.price;
        nft.nft.tags = this.tags;
        nft.nft.draft = this.draft;
        nft.nft.publishedProduct = this.publishedProduct;
        nft.nft.archived = this.archived;

        let shopifyResults = await axios({
        method:'post',
        url: `${this.apiUrl}shopify/product/create`,
        data: nft
        }).then(function(response){
          return response.data;
        }).catch(function() {
        });

        if(shopifyResults){
          nft.shopify_id    = shopifyResults.product.id
        }
        await firebase.firestore().collection('nfts').doc(this.id).set(nft);
        await this.getNfts();
        this.overlay = false;
        this.$pToast.open({
            message: "NFT Published!"
        });
        this.productSelected = false;
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.editModalOpen = false;
        this.isLoading = false;
        for(let button of buttons){
          button.style.display = 'none';
        }
      }
      else{
        this.isLoading = false;
      }
    },
    async update(){
        let propsL = Array.from(document.getElementsByClassName('prop-input'))
        let propsV = Array.from(document.getElementsByClassName('value-input'))

        let allPropsL = [];
        let allPropsV = [];

        for(let p of propsL){
         let pl = p.getElementsByTagName('input')[0].value;
         allPropsL.push(pl)
        }

        for(let p of propsV){
         let pl = p.getElementsByTagName('input')[0].value;
         allPropsV.push(pl)
        }

        this.attributes = [];

        if(allPropsL.length && allPropsV.length){
            for(let [i, prop] of allPropsL.entries()){
            if(prop !== '' && allPropsV[i] !== ''){
              let propObj = {
                "type": "property",
                "name": prop,
                "value": allPropsV[i]
              }
              this.attributes.push(propObj)
            }
          }
        }

      this.isLoading = true;
      this.checkSubmit();
      if(this.isOk){
        let buttons = Array.from(document.getElementsByClassName('Polaris-ButtonGroup'))
        for(let button of buttons){
          button.style.display = 'none';
        }
        this.isLoading = true;
        this.alert = false;
        this.overlay = true;
        let nft = {
          site: this.site,
          id: this.id,
          tokenId: this.tokenId,
          shopify_id: this.shopify_id,
          nft: {
            name: this.name,
            description: this.description,
            image: this.image,
            externalUrl: this.externalUrl,
            attributes: this.attributes,
            animationUrls: this.animationUrls,
            backgroundColor: this.backgroundColor,
          }
          // nft: {
          //   name: this.name,
          //   description: this.description,
          //   externalUrl: this.externalUrl,
          //   attributes: this.attributes,
          //   animationUrls: this.animationUrls,
          //   backgroundColor: this.backgroundColor
          // }
        }
        //console.log(nft)
        await axios({
        method:'post',
        url: `${this.apiUrl}nft/update`,
        data: nft
        }).then(function(){
        }).catch(function() {
        });

        nft.nft.price = this.price;
        nft.nft.tags = this.tags;
        nft.nft.draft = this.draft;
        nft.nft.publishedProduct = this.publishedProduct;

        await axios({
        method:'post',
        url: `${this.apiUrl}shopify/product/update`,
        data: nft
        }).then(function(){
        }).catch(function() {
        });

        await firebase.firestore().collection('nfts').doc(this.id).update(
          {
            "nft": {
              "name": nft.nft.name,
              "description": nft.nft.description,
              "externalUrl": nft.nft.externalUrl,
              "price": nft.nft.price,
              "publishedProduct": nft.nft.publishedProduct,
              "draft": nft.nft.draft,
              "tags": nft.nft.tags,
              "image": this.image,
              "published": this.published,
              "fungible": this.fungible,
              "attributes": nft.nft.attributes,
              "animationUrls": this.animationUrls,
              "backgroundColor": this.backgroundColor,
              "maxSupply": this.maxSupply,
              "archived": this.archived
            }
          }
          );
        await this.getNfts();
        this.overlay = false;
        this.$pToast.open({
            message: "NFT Updated!"
        });
        this.productSelected = false;
        this.isUpdate = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.editModalOpen = false;
        this.isLoading = false;
        for(let button of buttons){
          button.style.display = 'none';
        }
      }
      else{
        this.isLoading = false;
      }
    },
  async getNfts(){
    this.nfts = [];
    this.displayNfts = [];
    let nftsRef = await firebase.firestore().collection('nfts').where('site', '==', this.site).get()
    if(nftsRef.docs && nftsRef.docs.length > 0){
      for(let nft of nftsRef.docs){
        this.nfts.push(nft.data())
      }
        this.displayNfts = this.nfts.filter(nft => nft.nft.archived == this.showArchived);
        this.planNfts  = this.nfts.filter(nft => nft.nft.archived == false);
              switch(this.plan){
          case 'Basic':
          this.nftsAllowed = this.planNfts.length > 4 ? false : true;
          break;
          case 'Pro':
          this.nftsAllowed = this.planNfts.length > 24 ? false : true;
          break;
          default:
            this.nftsAllowed = true;
      }
    }
    else{
      this.errors = 'No NFTs found on your store.'
    }
  },
  truncateText(text){
    let len = 100;
    if(text.length > len){
      let newText = text.substring(0, len);
      newText += '...';
      return newText;
    }
    else{
      return text;
    }
  },
    async checkBilling(){
        let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
        let { billing } = siteRef.data()
        //check billing...
        if(billing.recurring_application_charge.test !== undefined){
          if(billing.recurring_application_charge.test === true && this.site !== this.shopifyDemoStore){
            if( this.$route.path !== '/settings'){
              this.$router.push({ path: `/settings`, query: { shop: this.site } })
              console.log('go redirect...')
            }
          }
        }
      }
  },
  mounted: async function () {
    await this.checkBilling()
    console.log(`NFT view 2.0 loaded...`)
    if(!this.settings){
      this.$router.push({ path: `/` })
    }
    await this.getNfts();
    this.isLoading = false
  }
}
</script>

<style scoped>
  .error {
    color: red;
    margin-top: 10px;
  }
  .product-container {
    margin-top: 20px;
  }
  .selected-product {
    display: flex;
  }
  .product-form,
  .publish-div {

  }
    .label {
    text-align: left;
  }
  .selected-product-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .margin-label {
    margin: 10px 0 20px 0;
  }
  .input {
    margin-top: -10px !important;
  }
  .product {
    padding: 10px;
    margin: 10px;
    border: 1px solid #babfc3;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 2px #eee;
    text-align: left;
  }
  .toggle {
    margin: 20px 0;
    text-align: left;
    display: flex;
    align-items: center;
  }
   .toggle .switch {
      margin-right: 10px !important;
    }
  .product.active {
    background: #f5f5f5;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  .product-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
  .product img {
    max-width: 200px;
    margin: 10px 0;
  }
  .submit-form.v-btn.disabled {
    pointer-events: none;
    cursor: default;
  }
  .submit-form {
    margin-top: 10px;
  }
  .product-form .v-text-field {
    align-self: center;
  }
  .nft-img,
  .current-image {
      margin: 10px;
      max-width: 50%;
      align-self: flex-start;
      display: flex;
  }
  .publish-btn {
    color: #fff;
  }
  .search-form {
    margin-top: 20px;
  }
  @media screen and (max-width: 768px){
      .selected-product {
    flex-direction: column;
  }
      .product-form,
  .publish-div {
    flex: 0 100%;
  }
    .nft-img {
      margin: 10px;
      max-width: 99%;
    }
    .product-form .v-text-field {
      max-width: 99%;
    }
    .product-container {
      grid-template-columns: 1fr 1fr;
    }
    .product img {
      max-width: 150px;
    }
  }
  .Polaris-FormLayout__Item {
    text-align: left;
  }
  #imageUpload {
    visibility: hidden;
    width: 1px;
  }
  #imageUploadButton {
    width: 100%;
    min-height: 50px;
    border: 1px solid #babfc3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-prop {
    margin: 0 auto;
    display: flex;
  }
    .props-container {
    display: flex;
    flex-wrap: wrap;
  }
  .props-container .input {
    width: 48%;
    flex: 0 48%;
    margin-bottom: 20px;
  }
  .props-container .input:nth-child(odd) {
    margin-right: 20px;
  }
</style>
