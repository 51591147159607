<template>
   <div class="loading">
      <PSpinner accessibilityLabel="Loading Spinner" />
   </div>
</template>

<script>
export default {
   name: 'Loading',
     data(){
      return {
         loadingTxt: 'Loading...'
      }
     },
};
</script>

<style scoped>
.loading {
   margin-top: 20px;
}
</style>
