import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//VIEWS
import Home from '../views/Home.vue'
import Install from '../views/Install.vue'
import Settings from '../views/Settings.vue'
import Products from '../views/Products.vue'
import NFTs from '../views/NFTs.vue'
import Orders from '../views/Orders.vue'
import Login from '../views/Login.vue'

const routes = [
      {
        path: '/install',
        name: 'Install',
        component: Install
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: '/products',
        name: 'Products',
        component: Products
      },
      {
        path: '/nfts',
        name: 'NFTs',
        component: NFTs
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/',
        name: 'Login',
        component: Login
      },
      {
        path: '*',
        name: 'Login',
        component: Login
      }
]

const router = new VueRouter({
    mode: 'history',
    routes
  })

export default router
