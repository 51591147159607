<template>
  <div class="home-wrapper">
    <template>
    <Loading v-if="isLoading"></Loading>
    <div class="home" v-else>
        <PChart
          type="bar"
          height="500"
          :series='[{"name": "Total","data":[nftsCreated,nftsPublished,nftsArchived,nftsPending,nftsMinted,nftsFulfilled]}]'
          :xAxis='["NFTs Created","NFTs Published","NFTs Archived","NFTs Pending","NFTs Minted","NFTs Fulfilled"]'
          :chartOptions="{}"
        />
      <PList class="list-container">
        <PListItem>
          NFTs Created
          <PCaption>NFT Templates created in this platform</PCaption>
        </PListItem>
        <PListItem>
          NFTs Published
          <PCaption>NFT Templates published that are live on Venly and Shopify</PCaption>
        </PListItem>
        <PListItem>
          NFTs Archived
          <PCaption>NFT Templates that are archived and not live on Venly and Shopify</PCaption>
        </PListItem>
        <PListItem>
          NFTs Pending
          <PCaption>NFT Order has been placed and is pending mint at Venly</PCaption>
        </PListItem>
        <PListItem>
          NFTs Minted
          <PCaption>NFT Order has been placed, minted by Venly, and is waiting to be fulfilled</PCaption>
        </PListItem>
        <PListItem>
          NFTs Fulfilled
          <PCaption>NFT Order has been placed, minted by Venly, fulfilled, and is complete</PCaption>
        </PListItem>
      </PList>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '../components/Loading.vue';
import firebase from 'firebase'

export default {
  name: 'Home',
  props: ['site', 'installed'],
  components: {
    Loading
  },
  data(){
    return {
     title: "Dashboard",
     isLoading: false,
     nftsMinted: 0,
     nftsPublished: 0,
     nftsCreated: 0,
     nftsPending: 0,
     nftsFulfilled: 0,
     nftsArchived: 0,
     nfts: [],
     errors: 'You have no nfts or orders on your site.',
     isReLoading: false,
     shopifyDemoStore: process.env.VUE_APP_SHOPIFY_DEMO_STORE
    }
  },
  methods: {
    async getData(){
      this.isReLoading = true;
      this.nfts = [];
      let nftsCreatedRef = await firebase.firestore().collection('nfts').where('site', '==', this.site).get();
      let nftsPublishedRef = await firebase.firestore().collection('nfts').where('site', '==', this.site).where('nft.published', '==', true).where('nft.archived', '==', false).get();
      let nftsArchivedRef = await firebase.firestore().collection('nfts').where('site', '==', this.site).where('nft.archived', '==', true).get();
      let nftsPendingRef = await firebase.firestore().collection('orders').where('data.site', '==', this.site).where('mintedStatus', '==', 'pending').get();
      let nftsMintedRef = await firebase.firestore().collection('orders').where('data.site', '==', this.site).where('mintedStatus', '==', 'SUCCEEDED').get();
      let nftsFulfilledRef = await firebase.firestore().collection('orders').where('data.site', '==', this.site).where('fulfillmentStatus', '==', 'success').where('fulfillmentId', '!=', 'error').get();
      let nftsFulfilledRefTwo = await firebase.firestore().collection('orders').where('data.site', '==', this.site).where('fulfillmentId', '==', 'error').where('mintedStatus', '==', 'SUCCEEDED').get();
      this.nftsFulfilled = nftsFulfilledRef.docs.length + nftsFulfilledRefTwo.docs.length;
      this.nftsCreated = nftsCreatedRef.docs.length;
      this.nftsMinted = nftsMintedRef.docs.length;
      this.nftsPending = nftsPendingRef.docs.length;
      this.nftsArchived = nftsArchivedRef.docs.length;
      this.nftsPublished = nftsPublishedRef.docs.length;
      this.isReLoading = false;
    },
    async checkBilling(){
        let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
        let { billing } = siteRef.data()
        //check billing...
        if(billing.recurring_application_charge.test !== undefined){
          if(billing.recurring_application_charge.test === true && this.site !== this.shopifyDemoStore){
            if( this.$route.path !== '/settings'){
              this.$router.push({ path: `/settings`, query: { shop: this.site } })
              console.log('go redirect...')
            }
          }
        }
      }
  },
  mounted: async function () {
    this.isLoading = true;
    await this.checkBilling()
    await this.getData();
    this.isLoading = false;
    window.setInterval(() => {
    //  this.getData();
    }, 30000)
  }
}
</script>

<style scoped>
  .error {
    color: red;
    margin-top: 10px;
  }
  .home-table__title {
    margin-top: 20px;
  }
  .home-table {
    padding: 10px;
  }
  .home-heading {
    margin-bottom: 20px;
  }
  .list-container {
    margin-top: 20px;
    text-align: left;
  }
</style>
