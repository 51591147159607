<template>
  <div class="footer"   v-if="site && installed && settings && session">
    <PLink url="https://venly.io" external monochrome>Learn more about Venly NFTs</PLink>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: ['site', 'installed', 'settings', 'session'],
  data(){
    return {
    }
  }
}
</script>
<style scoped>
.footer {
  margin: 40px 0;
}
</style>